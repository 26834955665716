<template>
  <header class="header">
      <div class="burger" @click="open()" :class="{ 'active': menu }">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
      </div>
      <div>
          <div class="main_title">{{ $t('header.title') }}</div>
          <div class="main_subtitle">{{ $t('header.subtitle') }}</div>
      </div>
  </header>
</template>

<script>

import {mapGetters} from 'vuex';
import {TimelineMax, TweenMax} from 'gsap';

export default {
  name: 'HeaderComponent',
  data () {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters('layout', {
      menu: 'getMenu'
    })
  },
  methods: {
    open () {
      this.$store.commit('layout/setMenu');

      if (window.innerWidth <= 1199) {
        TweenMax.set(('.menu li'), {opacity: 0, x: -100});
        var tl = new TimelineMax();
        tl
            .staggerTo(('.menu li'), 0.3, {opacity: 1, x: 0}, 0.1)
      }

    }
  },
  mounted: function () {
      var tl = new TimelineMax();
      tl
        .to(('.main_title'), 0.3, {opacity: 1, y: 0}, '+=0.5')
        .to(('.main_subtitle'), 0.5, {opacity: 1, y: 0})
  }
}
</script>

<style lang="scss" scoped>

</style>