// Default State here
const state = {
  list: []
};

// Getter functions
const getters = {
  getList: state => state.list,
  getLastNews: state => state.list.slice(0, 3)
};

// Actions
const actions = {
  async fetchList({ commit }) {
    await firebase.firestore().collection('news').get() // eslint-disable-line
      .then((querySnapshot) => {
        const projects = [];
        querySnapshot.forEach(doc => {
          projects.push({ id: doc.id, ...doc.data() });
        });
        commit('setList', projects);
      });
  }
};

// Mutations
const mutations = {
  setList(state, data) {
    state.list = data.sort((a, b) => (new Date(a.date) > new Date(b.date)) ? -1 : new Date(a.date) < new Date(b.date) ? 1 : 0);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
