// const AuthPage = () => import(/* webpackChunkName: "public" */`./containers/AuthPage`);
// const AuthForm = () => import(/* webpackChunkName: "public" */`./components/AuthForm`);
const HomePage = () => import(/* webpackChunkName: "public" */`./containers/HomePage`);
const BioPage = () => import(/* webpackChunkName: "bio" */`./containers/BioPage`);
const ProjectsPage = () => import(/* webpackChunkName: "projects" */`./containers/ProjectsPage`);
const GalleryPage = () => import(/* webpackChunkName: "gallery" */`./containers/GalleryPage`);
const MediaPage = () => import(/* webpackChunkName: "media" */`./containers/MediaPage`);
const ProjectPage = () => import(/* webpackChunkName: "project" */`./containers/ProjectPage`);

export default [
  {
    path: '',
    name: 'home',
    component: HomePage
  },
  {
    path: 'bio',
    name: 'bio',
    component: BioPage
  },
  {
    path: 'projects',
    name: 'projects',
    component: ProjectsPage
  },
  {
    path: 'gallery',
    name: 'gallery',
    component: GalleryPage
  },
  {
    path: 'media',
    name: 'media',
    component: MediaPage
  },
  {
    path: ':id',
    name: 'project',
    component: ProjectPage,
    props: true
  }
];