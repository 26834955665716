<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style lang="scss" >
  @import './styles';
</style>
