<template>
  <div class="content">
    <header-component />
    <sidebar-component />
    <router-view />
    <div class="no_scroll_bg" @click="open()" :class="{ 'active': menu }"></div>
  </div>
</template>

<script>
import SidebarComponent from '@/shared/components/Sidebar';
import HeaderComponent from '@/shared/components/Header';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MainLayoutComponent',
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t('header.title'),
      meta: [
        {
          name: 'description',
          content: this.$t('description'),
        },
        {
          property: 'og:description',
          content: this.$t('description'),
        },
        {
          property: 'og:title',
          content: this.$t('header.title'),
        }
      ]
    }
  },
  components: {
    HeaderComponent,
    SidebarComponent
  },
  computed: {
    ...mapGetters('layout', {
      menu: 'getMenu'
    })
  },
  methods: {
    ...mapActions('projects', [
      'fetchList'
    ]),
    ...mapActions('gallery', {
      fetchListGallery: 'fetchList'
    }),
    ...mapActions('news', {
      fetchListNews: 'fetchList'
    }),
    open () {
      this.$store.commit('layout/setMenu');
    }
  },
  mounted() {
    this.fetchList();
    this.fetchListGallery();
    this.fetchListNews();
  }
}
</script>

<style lang="scss" scoped>

</style>