import VueI18n from 'vue-i18n';
import Vue from 'vue';

import en from '@/lang/en.json';
import ru from '@/lang/ru.json';
import uk from '@/lang/uk.json';

import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '../constants/trans';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE, // set locale
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: { uk, en, ru } // set locale messages
});
