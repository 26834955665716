<template>
    <footer class="footer">
        <div class="lang">
            <div class="lang_item" @click="changeLang('uk')" v-if="currentLanguage !== 'uk'">укр</div>
            <div class="lang_item" @click="changeLang('ru')" v-if="currentLanguage !== 'ru'">рус</div>
            <div class="lang_item" @click="changeLang('en')" v-if="currentLanguage !== 'en'">en</div>
        </div>
        <div class="social">
            <a href="https://www.facebook.com/NikBag" target="_blank" rel="noreferrer" title="Facebook Page"><i class="mdi mdi-facebook"></i></a>
        </div>
        <div class="copyright">© 2020 developed by <a href="http://yual.io/" target="_blank" rel="noreferrer">yual</a></div>
    </footer>
</template>

<script>
import { Trans } from '@/shared/utils/TranslateMiddleware';
import { TimelineMax } from 'gsap';

export default {
    name: 'FooterComponent',
    computed: {
        currentLanguage () {
            return Trans.currentLanguage;
        }
    },
    mounted () {
        const tl = new TimelineMax();
        tl
            .staggerTo(('.lang_item'), 0.3, {opacity: 1, scale: 1}, 0.1, '+=0.5')
            .staggerTo(('.footer .social a'), 0.3, {opacity: 1, x: 0}, 0.15)
            .to(('.copyright'), 0.3, {opacity: 1, y: 0});
    },
    methods: {
        changeLang(lang) {
            const to = this.$router.resolve({ params: { lang: lang } });

            Trans.changeLanguage(lang).then(() => {
                this.$router.push(to.location);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .lang_item {
        &:hover {
            cursor: pointer;
        }
    }
</style>