<template>
    <aside class="sidebar" :class="{ 'active': menu }">
        <!-- <header-component /> -->
        <header class="header_sidebar">
            <div>
                <div class="main_title">{{ $t('header.title') }}</div>
                <div class="main_subtitle">{{ $t('header.subtitle') }}</div>
            </div>
        </header>
        <ul class="menu">
            <li @click="open()"><router-link :to="$i18nRoute({ name: 'home' })">{{ $t('menu.home') }}</router-link></li>
            <li @click="open()"><router-link :to="$i18nRoute({ name: 'bio' })">{{ $t('menu.biography') }}</router-link></li>
            <li @click="open()"><router-link :to="$i18nRoute({ name: 'projects' })">{{ $t('menu.projects') }}</router-link></li>
            <li @click="open()"><router-link :to="$i18nRoute({ name: 'gallery' })">{{ $t('menu.gallery') }}</router-link></li>
            <li @click="open()"><router-link :to="$i18nRoute({ name: 'media' })">{{ $t('menu.news') }}</router-link></li>
        </ul>
        <footer-component />
    </aside>
</template>

<script>
// import HeaderComponent from '@/shared/components/Header';
import FooterComponent from '@/shared/components/Footer';
import {mapGetters} from 'vuex';

import {TimelineMax} from 'gsap';

export default {
    name: 'SidebarComponent',
    components: {
        // HeaderComponent,
        FooterComponent
    },
    computed: {
        ...mapGetters('layout', {
            menu: 'getMenu'
        })
    },
    methods: {
        open () {
            if (window.innerWidth <= 1199) {
                this.$store.commit('layout/setMenu');
            }
        }
    },
    mounted: function () {
        var tl = new TimelineMax();
        tl
            .staggerTo(('.menu li'), 0.3, {opacity: 1, x: 0}, 0.1, '+=0.5')
            .to(('.sidebar'), 0.3, {borderColor: '#8f90a6'})
    }
}
</script>

<style lang="scss" scoped>

</style>