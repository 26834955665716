// Default State here
const state = {
    menu: false
};

// Getter functions
const getters = {
    getMenu: state => state.menu
};

// Actions
const actions = {
};

// Mutations
const mutations = {
    setMenu(state) {
        state.menu = !state.menu;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
