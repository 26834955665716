import Vue from 'vue';
import VuePackeryPlugin from 'vue-packery-plugin';
import SocialSharing from 'vue-social-sharing';
import Vuebar from 'vuebar';
import VueMeta from 'vue-meta';

import { gsap, TimelineMax, TweenMax } from 'gsap/all';
gsap.registerPlugin(TimelineMax, TweenMax);

import './shared/utils/firebase';

import App from './App.vue';

import store from './store';
import router from './routes';

import { i18n } from './shared/utils/i18n';
import { Trans } from './shared/utils/TranslateMiddleware';

import 'jquery';
import '@fancyapps/fancybox';

Vue.config.productionTip = false;
Vue.use(VuePackeryPlugin);
Vue.use(SocialSharing);
Vue.use(Vuebar);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app');
