import Vue from 'vue';
import Router from 'vue-router';

import { Trans } from './shared/utils/TranslateMiddleware';

import MainLayout from './shared/containers/MainLayout';

import { routes as Public } from './modules/Public';

Vue.use(Router);

export default new Router(
  {
    mode: 'history',
    routes: [
      {
        path: '/:lang',
        component: MainLayout,
        beforeEnter: Trans.routeMiddleware,
        scrollBehavior (to, from, savedPosition) { // eslint-disable-line
          // if (savedPosition) {
          //   return savedPosition;
          // }
          return { x: 0, y: 0 };
        },
        children: [
          ...Public
        ]
      },
      {
        // Redirect user to supported lang version.
        path: '*',
        redirect () {
          return Trans.getUserSupportedLang();
        }
      }
    ]
  }
);